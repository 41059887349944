import React from 'react';
import {
  Switch,
  Route,
  Link
} from 'react-router-dom';
import {Card, Image} from 'react-bootstrap';
import Typed from 'react-typed';

import headshot from './headshot.jpg';

import KringleCon2020 from './kringlecon2020/App';
import KringleCard from './kringlecon2020/header.jpg';

import GhostCard from './ghost/header.png';

import Valentines from './cara/ValentinesDay';

import './App.css';

/*
 * Color Palette:
 *   http://colormind.io
 * #144d97 - Dark Blue
 * #137bb0 - Mid Blue
 * #28c2d3 - Light Blue
 * #f1d272 - Pale Yellow
 * #e74e4e - Pale Red
 */

const TYPED_STRINGS = [
  'B.S. Computer Science',
  'University of Michigan',
  'Amazon Software Development Engineer'
];

class Home extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="Home">
        <div className="Header">
          <Image className="Headshot" align="right" src={headshot} roundedCircle />
          <Typed strings={['Kevin Tarta']} showCursor={false} typeSpeed={50} backSpeed={60} /><br />
          <Typed strings={TYPED_STRINGS} typeSpeed={50} backSpeed={60} startDelay={1000} loop />
        </div>
        <div className="Card-container">
          <Card className="Card">
            <Link to="kringlecon2020">
              <Card.Img className="Card-image" src={KringleCard} />
              <Card.Text>KringleCon 2020 Writeup</Card.Text>
            </Link>
          </Card>
          <Card className="Card">
            <Link to="ghost">
              <Card.Img className="Card-image" src={GhostCard} />
              <Card.Text>Security + Technical Blog</Card.Text>
            </Link>
          </Card>
        </div>
      </div>
    );
  }
}

export default function App() {
  return (
    <Switch>
      <Route path="/ghost" component={() => { 
        window.location.href = "https://ghost.kevintarta.com"; 
        return null;
      }}/>
      <Route exact path="/cara-be-my-valentine">
        <Valentines />
      </Route>
      <Route exact path="/kringlecon2020">
        <KringleCon2020 />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
    </Switch>
  );
}
