import React from 'react';
import {Container, Row, Col, Image, Form, Button, Alert} from 'react-bootstrap';

import ValentineImage from './valentine.jpg';
import './ValentinesDay.css';


class Valentines extends React.Component {
    constructor() {
        super();
        this.state = {
            choice: "",
            feedback: "",
            show: false,
        };
    }

    callLambda = (event) => {
        event.preventDefault();
        console.log(event);

        fetch("https://qa64ywofri.execute-api.us-east-1.amazonaws.com/cara-valentines", {
            method: "POST",
            body: JSON.stringify({
                choice: this.state.choice,
                feedback: this.state.feedback,
            }),
        })
        .catch(error => console.log(error))
        .then(response => response.json())
        .then(data => {
            console.log(data);
            this.setState({
                show: true,
            });
        });
    }

    onChoiceChange = (newChoice) => {
        this.setState({
            choice: newChoice
        });
        // console.log(this.state);
    }

    onFeedbackChange = (event) => {
        this.setState({
            feedback: event.target.value
        });
        // console.log(this.state);
    }

    render() {
        return (
            <Container fluid className="Valentine-app">
                <Row className="Valentine-app-container">
                    <Col xs={1} sm={2} md={2} />
                    <Col xs={10} sm={8} md={8} className="Valentine-form">
                        <Image roundedCircle src={ValentineImage} className="Valentine-image" />
                        <Form onSubmit={this.callLambda}>
                            <Form.Group className="Valentine-question">
                                <Form.Label>Cara, will you be my Valentine?</Form.Label>
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    id="answer-yes"
                                    label="yes"
                                    onChange={(e) => this.onChoiceChange("yes")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    id="answer-yes-emphasized"
                                    label="yes!"
                                    onChange={(e) => this.onChoiceChange("yes!")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    id="answer-absolutely-yes"
                                    label="absolutely yes!!"
                                    onChange={(e) => this.onChoiceChange("absolutely yes!!")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    id="answer-yes-duh"
                                    label="yes duhhh!?"
                                    onChange={(e) => this.onChoiceChange("yes duhhh!?")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="group1"
                                    id="answer-no??"
                                    label="no??"
                                    onChange={(e) => this.onChoiceChange("no??")}
                                />
                                <Form.Label>Any feedback you may have:</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={this.onFeedbackChange} />
                            </Form.Group>
                            <Button className="Valentine-form-submit" variant="primary" type="submit">
                                Shoot Cupid's Arrow
                            </Button>
                            {this.state.show && 
                                <Alert variant="success">
                                    <Alert.Heading>Emailed kevtar@gmail.com with your feedback ;) thanks</Alert.Heading>
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => this.setState({show: false})} variant="outline-success">
                                            Close!
                                        </Button>
                                    </div>
                                </Alert>
                            }
                        </Form>
                    </Col>
                    <Col xs={1} sm={2} md={2} />
                </Row>
            </Container>
        );
    }
}

export default Valentines;
